.scroll {
  overflow-y: scroll;
  scroll-behavior: smooth;
  height: 57vh;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.scroll::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
*{
  font-family: 'Poppins';
}
body{
  background-color: #FEFEFE !important;
   font-family: 'Poppins';
}
.commentScroll {
  overflow-y: scroll;
  height: 70vh;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.commentScroll::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.commentScroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}


* {
  padding: 0;
  margin: 0;
}